<template>
  <v-col class="hundredAlmost">
    <v-row>
      <v-col>
        <v-btn color="white"> Turn Timer: {{ turnTimer }} </v-btn>
      </v-col>
      <v-col>
        <v-btn color="white" :class="isHidden" @click="skip">
          Finish Turn
        </v-btn>
      </v-col>
    </v-row>
    <v-row id="canvas-row" class="hundred">
      <img
        id="pen"
        width="30px"
        style="position:absolute;"
        src="../assets/fountain_pen.svg"
      />
      <canvas
        id="canvas"
        @mousedown="drawEnabled"
        @mouseup="drawDisabled"
        @mousemove="draw"
        @touchmove="draw"
        @touchstart="drawEnabled"
        @touchend="drawDisabled"
      >
      </canvas>
    </v-row>
  </v-col>
</template>

<script>
import {
  MOUSE_MOVE,
  MOUSE_UP,
  MOUSE_DOWN,
  SET_CANVAS,
  FINISH_TURN,
  SET_PEN,
  ADJUST_PEN,
} from "../constants/mutation-types";

export default {
  name: "Canvas",
  props: {},
  data: () => {
    return {
      now: Date.now(),
    };
  },
  methods: {
    skip: function() {
      if (this.$store.getters.isActive) {
        this.$store.commit(FINISH_TURN);
      }
    },
    draw: function(e) {
      if (e) {
        e.preventDefault();
      }
      if (this.$store.getters.isActive) {
        this.$store.commit(MOUSE_MOVE, {
          x: e.offsetX,
          y: e.offsetY,
        });
      }
    },
    drawEnabled: function(e) {
      if (e) {
        e.preventDefault();
      }
      if (this.$store.getters.isActive) {
        this.$store.commit(MOUSE_DOWN, {});
      }
    },
    drawDisabled: function(e) {
      if (e) {
        e.preventDefault();
      }
      if (this.$store.getters.isActive) {
        this.$store.commit(MOUSE_UP, {});
      }
    },
  },
  computed: {
    isHidden() {
      return this.$store.getters.isActive ? "" : "hidden";
    },
    turnOrder() {
      return this.$store.state.turnOrder;
    },
    turnTimer() {
      let time = new Date(this.$store.state.turnTimeEnd).getTime();
      return Math.max(Math.round((time - this.now) / 1000), 0);
    },
    playerMap() {
      return this.$store.state.playerMap;
    },
  },
  mounted() {
    let cr = document.getElementById("canvas-row")
    let c = document.getElementById("canvas");
    let rect = cr.getBoundingClientRect()
    let newWidth = rect.right - rect.left
    let ctx = c.getContext("2d");
    ctx.canvas.width = newWidth;
    ctx.canvas.height = newWidth * .75
    this.$store.commit(SET_CANVAS, { ctx });
    let p = document.getElementById("pen");
    this.$store.commit(SET_PEN, { p });
    window.addEventListener('resize', () => {
      let cr = document.getElementById("canvas-row")
      let rect = cr.getBoundingClientRect()
      let newWidth = rect.right - rect.left
      let c = document.getElementById("canvas");
      let ctx = c.getContext("2d");
      let data = c.toDataURL()
      ctx.canvas.width = newWidth;
      ctx.canvas.height = newWidth * .75
      var img=new Image();
      img.onload=function(){
          ctx.drawImage(img,0,0,img.width,img.height,0,0,c.width,c.height);
      }
      img.src = data
    })
  },
  created() {

    setInterval(() => {
      this.now = Date.now();
      let c = document.getElementById("canvas");
      let p = document.getElementById("pen");
      if (p !== null)
          this.$store.commit(SET_PEN, { p });
      if (c !== null)
        this.$store.commit(ADJUST_PEN, { rect: c.getBoundingClientRect() });
    }, 1000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.chatBox {
  height: 30em;
  overflow-y: scroll;
}

.voted {
  background-color: blue;
}
.unvoted {
  background-color: greenyellow;
}
#canvas {
  background-color: white;
  cursor: none;
  /* height: 100%;
    width: 98%; */
}
#pen {
  pointer-events: none;
}
.hidden {
  display: none;
}
/* .hundred {
    height: 100%;
    width: 100%;
}
.hundredAlmost {
    height: 100%;
    width: 100%
} */
</style>
