<template>
  <v-col class="scoreboard">
    <v-card
      :color="getColor(index, player)"
      v-for="(player, index) in scoreSorted"
      :key="index"
      elevate="2"
      width="100%"
      style="position: relative"
    >
      <v-row no-gutters>
        {{ index + 1 }}. {{ player.name }} Score: {{ player.score }}
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "ScoreBoard",
  props: {
    msg: String,
  },
  data: () => {
    return {
      guess: "",
    };
  },
  computed: {
    scoreSorted() {
      return this.$store.getters.getPlayersByScore;
    },
  },
  methods: {
    getColor: function(index, player) {
      if (player.score === 0) {
        return "white:"
      }
      if (index === 0) {
        return "#e2c226";
      } else if (index === 1) {
        return "#9c9a9a";
      } else if (index === 2) {
        return "#7b5707";
      } else {
        return "white";
      }
    },
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.scoreboard {
  color: grey;
  height: 5em;
  overflow-y: scroll;
  width: 97%;
-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.scoreboard::-webkit-scrollbar {
  display: none;
}
</style>
