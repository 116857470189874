<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
    </v-app-bar>

    <v-main>
      <Game/>
    </v-main>
  </v-app>
</template>

<script>
import Game from './components/Game.vue'
import {SET_UUID} from './constants/mutation-types.js'

export default {
  name: 'App',

  components: {
    Game,
  },

  data: () => ({
    //
  }),
  methods:{
    },
  mounted: function () {
    this.$nextTick( function () {
      let uuid = this.$cookies.get("uuid");
      if (uuid == null) {
        uuid = this.$uuid.v4()
        this.$cookies.set("uuid", uuid)
      }
      this.$store.commit(SET_UUID, {uuid})
    })
  }
}
</script>

<style scoped>
#app {
  background-color: #dedddd;
}
</style>
