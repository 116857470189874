export const SET_UUID = 'SET_UUID'
export const CONNECT = 'CONNECT'
export const JOIN = 'JOIN'
export const SEND_CHAT = 'SEND_CHAT'
export const UPDATE_TIME_LIMIT = 'UPDATE_TIME_LIMIT'
export const ADD_CUSTOM_WORD_ROW = 'ADD_CUSTOM_WORD_ROW'
export const UPDATE_CUSTOM_WORD = 'UPDATE_CUSTOM_WORD'
export const MOUSE_MOVE = 'MOUSE_MOVE'
export const START = 'START'
export const VOTE = 'VOTE'
export const SET_CANVAS = 'SET_CANVAS'
export const MOUSE_DOWN = 'MOUSE_DOWN'
export const MOUSE_UP = 'MOUSE_UP'
export const FINISH_TURN = 'FINISH_TURN'
export const UPDATE_GUESS = 'UPDATE_GUESS'
export const SET_PEN = 'SET_PEN'
export const ADJUST_PEN = 'ADJUST_PEN'
