<template>
  <v-container class="game" fluid>
      <v-row>
        <v-spacer />
        <v-col cols="10"  offset="0" justify="center" align="center">
          <Login v-if="!connected"></Login>
          <Lobby v-if="connected && state==='LOBBY'"></Lobby>
          <ActiveGame v-if="connected && state==='ACTIVE'" />
        </v-col>
        <v-spacer />
      </v-row>
  </v-container>
</template>

<script>
import Lobby from "./Lobby";
import Login from "./Login";
import ActiveGame from "./ActiveGame";

export default {
  name: "Game",
  props: {
    msg: String,
  },
  computed: {
    connected() {
      return this.$store.state.connected;
    },
    state() {
        return this.$store.state.state;
    }
  },
  components: {
    Lobby,
    Login,
    ActiveGame,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
