<template>
  <v-container class="login">
    <v-form ref="form">
      <v-row>
        <v-spacer />
        <v-col cols="4" md="4" offset="0" justify="center" align="center">
          <v-text-field solo v-model="name" label="Name"></v-text-field>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="4" md="4" offset="0" justify="center" align="center">
          <v-text-field
            solo
            v-model="roomCode"
            label="Room Code"
            maxlength = 6
            :counter="6"
            :error-messages="roomError"
          ></v-text-field>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="12" md="8" offset="0" justify="center" align="center">
          <v-btn
            :disabled="roomCode.length !== 6 || name.length < 1"
            @click="join"
            >Join Game</v-btn
          >
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="12" md="8" offset="0" justify="center" align="center">
          <v-btn :disabled="name.length < 1" @click="create">Create Game</v-btn>
        </v-col>
        <v-spacer />
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { CONNECT } from "../constants/mutation-types";

export default {
  name: "Login",
  props: {
    msg: String,
  },
  data: () => {
    return {
      name: "",
      roomCode: "",
      roomError: "",
    };
  },
  methods: {
    create: async function() {
      const msg = {
        uuid: this.$store.state.uuid,
        playerName: this.name,
        roomCode: "",
      };
        await fetch("http://144.126.213.200:8080/messaging-stomp-websocket-2.3.2.RELEASE/app/createRoomCode", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(msg), // body data type must match "Content-Type" header
      }).then(response => 
          {
            return response.text()
          }).then(data => {
            this.$store.commit(CONNECT, { roomCode: data })});
      
    },
    join: async function () {
      this.roomError = ''
        const msg = {
        uuid: this.$store.state.uuid,
        playerName: this.name,
        roomCode: this.roomCode.toUpperCase(),
      };
        await fetch("http://144.126.213.200:8080/messaging-stomp-websocket-2.3.2.RELEASE/app/joinRoom", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(msg), // body data type must match "Content-Type" header
      }).then(response => 
          {
            return response.text()
          }).then(data => {
            if (data.length === 6) {
              this.roomCode = data
              this.$store.commit(CONNECT, {roomCode: this.roomCode})

            }
            else {
              this.roomError = data
            }
            });
    },
  },
  computed: {
    uuid() {
      return this.$store.state.uuid;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
