<template>
  <v-container class="vote">
    <v-row class="text-h6"> Current Artist: {{ getCurrentArtist() }} </v-row>
    <v-row class="text-h6"> Early Vote Bonus: {{ earlyVoteBonus }} </v-row>
    <v-row
      class="text-body-1
 margin-top-1"
    >
      You can click the players name to the left to vote for them as the
      conartist/fakeartist/imposter.
    </v-row>
    <v-row
      class="text-body-1
 margin-top-1 mb"
    >
      When you vote early each turn you can gain potential points if you voted
      correctly.
    </v-row>

    <v-row v-for="(player, index) in turnOrder" :key="index" no-gutters>
      <v-card
        :color="isVotedFor(player)"
        @click="vote(player)"
        elevate="2"
        width="100%"
        style="position: relative"
      >
        <v-col cols="12" align="center" justify="center" style="height:100%">
          {{ getPlayerOrderCard(player) }}
        </v-col>
      </v-card>
    </v-row>
    <v-row
      class="text-body-1
 mt"
    >
      Blue/Purple means it is that players current turn.</v-row
    >
    <v-row
      class="text-body-1
 margin-top-1"
    >
      Purple/Red means you've voted for them.</v-row
    >
    <v-row
      class="text-body-1
 margin-top-1"
    >
      White you haven't voted for them and it's not their turn.
    </v-row>
    <v-row
      class="text-body-1
 margin-top-1"
    >
      Scoring:
    </v-row>
    <v-row
      class="text-body-1
 margin-top-1"
    >
      If the fake artist gets get's less than half the vote up to 500 pts are added to their score.
    </v-row>
        <v-row
      class="text-body-1
 margin-top-1"
    >
      If you vote for the correct person who is fake. Your score is increased by 50 + the early voting bonus.
    </v-row>
            <v-row
      class="text-body-1
 margin-top-1"
    >
      If the fake artist get's caughte but guesses the secret word correctly 200 pts is added to their score.
    </v-row>
  </v-container>
</template>

<script>
import { VOTE } from "../constants/mutation-types";

export default {
  name: "Vote",
  props: {
    msg: String,
  },
  data: () => {
    return {
      uuidFake: "fuck",
      fakePlayers: ["Ricky", "Matt", "Trent"],
      chatMsg: "",
    };
  },
  methods: {
    isVotedFor: function(uuid) {
      if (this.$store.state.activePlayer === uuid) {
        return this.$store.state.playerMap[this.$store.state.uuid].guess ===
          uuid
          ? "purple"
          : "blue";
      }
      return this.$store.state.playerMap[this.$store.state.uuid].guess === uuid
        ? "red"
        : "white";
    },

    vote: function(uuid) {
      this.$store.commit(VOTE, { suspect: uuid });
    },

    getPlayerOrderCard: function(uuid) {
      return uuid === this.$store.state.uuid
        ? `${this.$store.state.playerMap[uuid].nickname} (you)`
        : this.$store.state.playerMap[uuid].nickname;
    },
    getCurrentArtist: function() {
      return this.$store.state.playerMap[this.$store.state.activePlayer].nickname;
    },
  },
  computed: {
    currentTurn() {
      return this.$store.state.turn;
    },

    turnOrder() {
      return this.$store.state.turnOrder;
    },

    playerMap() {
      return this.$store.state.playerMap;
    },

    earlyVoteBonus() {
      return this.$store.state.playerMap[this.$store.state.uuid].earlyVoteBonus;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.chatBox {
  height: 30em;
  overflow-y: scroll;
}

.voted {
  background-color: blue;
}
.unvoted {
  background-color: greenyellow;
}
.margin-top-1 {
  margin-top: 1em;
}
.mb {
  margin-bottom: 4em;
}
.mt {
  margin-top: 2em;
}
</style>
