<template>
  <v-container class="game" fluid>
    <v-row>
      <v-btn><img width="30px" src="../assets/fountain_pen.svg"/></v-btn>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "CostumeSelector",
  props: {
  },
  data: () => {
    return {
      uuidFake: "fuck",
      fakePlayers: ["Ricky", "Matt", "Trent"],
      chatMsg: "",
    };
  },
  methods: {
      scrollToEnd: function () {
          this.$refs.chatBox.scrollTop = this.$refs.chatBox.lastElementChild.offsetTop;
      }
  },
  computed: {
    chatHistory() {
      return this.$store.state.markers;
    },
    roomCode() {
      return this.$store.state.roomCode;
    },

    playerMap () {
        return this.$store.state.playerMap
    },

    isActive () {
        return this.$store.state.state === "ACTIVE"
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.chatBox {
    height: 30em;
    overflow-y: scroll;
    overflow-wrap: anywhere;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.chatBox::-webkit-scrollbar {
  display: none;
}
</style>
