<template>
  <v-container class="game" fluid>
    <v-row>
      <v-col class="mr" cols="2" justify="left" align="left">
          <Vote/>
      </v-col>
      <v-col cols="6"  justify="center" align="center">
        <v-row class="text-h5" no-gutters> 
            <v-spacer/>
                <v-col cols="4">Topic: {{topic}} </v-col>
            <v-spacer/>
        </v-row>
        <v-row class="text-h5" no-gutters>
            <v-spacer/>
                <v-col cols="4" md="4" sm="12">Secret Word: {{secretWord}} </v-col>
                <v-col cols="3" md="3" sm="12" v-if="isConArtist">
                    <v-text-field  v-model="guess" @change="updateGuess" solo placeholder="guess"> </v-text-field> 
                    </v-col>
            <v-spacer/>
        </v-row>
        <v-row width="100%" height="100%">
            <Canvas/>
        </v-row>
      </v-col>
      <v-col cols="3" justify="right" align="right">
          <v-row>
              <ScoreBoard> </ScoreBoard>
          </v-row>
        <v-row>
            <Chat />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chat from "./Chat";
import Vote from "./Vote";
import Canvas from "./Canvas";
import ScoreBoard from "./ScoreBoard";
import {UPDATE_GUESS} from "../constants/mutation-types"

export default {
  name: "Game",
  props: {
    msg: String,
  },
  data: () => {
      return {
          guess: ""
      }
  },
  computed: {
    uuid() {
      return this.$store.state.uuid;
    },
    isConArtist() {
        return this.$store.state.uuid === this.$store.state.conartist
    },
    secretWord() {
      if (this.$store.state.conartist === this.$store.state.uuid) {
        const word = this.$store.state.secretWord;
        let retVal = "";
        for (var i = 0; i < word.length; i++) {
          if (word.charAt(i).match(/[a-zA-Z]/i) ) {
              retVal += '*'
          } else {
              retVal += word.charAt(i)
          }
        }
        return retVal
      }
      return this.$store.state.secretWord;
    },
    topic () {
        return this.$store.state.topic;
    },
  },
  methods: {
      updateGuess: function () {
          this.$store.commit(UPDATE_GUESS, {guess: this.guess})
      }
  },
  components: {
    Chat,
    Vote,
    Canvas,
    ScoreBoard,

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.mr {
    margin-right: 3em;
}
</style>
