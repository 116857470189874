<template>
  <v-container class="chat" fluid>
    <v-row>
      <v-spacer />
      <v-col cols="12" md="12" offset="0" justify="left" align="left">
          <v-container class="chatBox" ref="chatBox">
            <v-row v-for="item in chatHistory" :key="item.message">
                {{isActive ? playerMap[item.uuid].nickname : playerMap[item.uuid].name}} {{': ' + item.msg}}
            </v-row>
          </v-container>
        <v-text-field
          solo
          v-model="chatMsg"
          @keydown.enter="sendChat"
          label="Type to chat"
          :counter="240"
        ></v-text-field>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import {SEND_CHAT} from "../constants/mutation-types"

export default {
  name: "Chat",
  props: {
    msg: String,
  },
  data: () => {
    return {
      uuidFake: "fuck",
      fakePlayers: ["Ricky", "Matt", "Trent"],
      chatMsg: "",
    };
  },
  methods: {
      sendChat: function () {
        const chatMsg = this.chatMsg
        this.$store.commit(SEND_CHAT, {chatMsg})
        this.chatMsg = ""
      },

      scrollToEnd: function () {
          if (this.$refs.chatBox !== null && this.$refs.chatBox.lastElementChild !== null) 
            this.$refs.chatBox.scrollTop = this.$refs.chatBox.lastElementChild.offsetTop;
          
      }
  },
  computed: {
    chatHistory() {
      return this.$store.state.chatHistory;
    },
    roomCode() {
      return this.$store.state.roomCode;
    },

    playerMap () {
        return this.$store.state.playerMap
    },

    isActive () {
        return this.$store.state.state === "ACTIVE"
    }
  },
  updated () {
      this.$nextTick(() => this.scrollToEnd())
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.chatBox {
    height: 30em;
    overflow-y: scroll;
    overflow-wrap: anywhere;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.chatBox::-webkit-scrollbar {
  display: none;
}
</style>
