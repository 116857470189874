<template>
  <v-container class="game" fluid>
    <v-row>
      <v-spacer />
      <v-col cols="5" offset="0" justify="left" align="left">
        <v-row class="text-h4">Room Code: {{ roomCode.toUpperCase() }}</v-row>
        <v-row class="text-h5 margin-top-50">
            Drawing Time Limit ({{ drawingTimeLimitComputed }}) seconds :
          <v-slider
            :disabled="uuid !== host"
            v-model="drawingTimeLimit"
            @change="updateTimeLimit"
            ticks
            thumb-label
            step="1"
            min="15"
            max="60"
          />
        </v-row>
        <v-row class="text-h5">
          <v-btn :disabled="uuid !== host || playerList.length < 2" title="At least two people are required to start the game" @click="start">Start Game</v-btn>
        </v-row>

        <v-row class="text-h5 margin-top-50">
          <v-row class="text-h6">
            <ul>
              <li>There is a conartist among us.</li>
              <li>We must draw the secret word to confirm who is real and who isn't.</li>
              <li>Use the area below to add your own topics and words for each topic.</li>
            </ul>
          </v-row>
        </v-row>
        <v-row>
          <v-checkbox v-model="customWordsOnly" label="Use only custom words in game"> </v-checkbox>
        </v-row>
        <v-row class="text-h5 margin-top-50">
          <div>Topic / Comma Separated Words</div>
        </v-row>
        <v-row v-for="(item, index) in customWords" :key="index">
          <v-col cols="4">
            <v-text-field
              :disabled="uuid !== host"
              placeholder="Food"
              solo
              v-model="item.topic"
            >
            </v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              :disabled="uuid !== host"
              placeholder="Ramen, Sushi, Yogurt"
              solo
              v-model="item.words"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-btn :disabled="uuid !== host" @click="addRow">Add Row</v-btn>
        </v-row>
      </v-col>
      <v-col cols="5" offset="0" justify="right" align="right">
        <v-row justify="center" align="center">
          <CostumeSelector/>
        </v-row>
        <v-row>
          <Chat />
        </v-row>
      </v-col>
      <v-col cols="2" offset="0" justify="right" align="right">
        <v-list disabled>
          <v-subheader>Players in Lobby</v-subheader>
          <v-list-item-group>
            <v-list-item
              v-for="(value, name, index) in playerList"
              :key="index"
            >
              {{ name + 1 }}. {{ value.name }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
        Last Round:
        <ScoreBoard/>
      </v-col>
      <v-spacer />
    </v-row>
  </v-container>
</template>

<script>
import Chat from "./Chat";
import ScoreBoard from "./ScoreBoard"
import CostumeSelector from "./CostumeSelector"
import {
  UPDATE_TIME_LIMIT,
  UPDATE_CUSTOM_WORD,
  START,
} from "../constants/mutation-types";
export default {
  name: "Lobby",
  props: {
  },
  components: {
    Chat,
    ScoreBoard,
    CostumeSelector
  },
  data: () => {
    return {
      drawingTimeLimit: 0,
      customWords: [{ topic: "", words: "" }],
      customWordsOnly: false,
    };
  },
  computed: {
    roomCode() {
      return this.$store.state.roomCode;
    },
    drawingTimeLimitComputed() {
      return this.$store.state.turnLength;
    },
    playerList() {
      return this.$store.state.playerList;
    },
    host() {
      return this.$store.state.host;
    },
    uuid() {
      return this.$store.state.uuid;
    },
  },
  methods: {
    updateCustomWord: function(index) {
      this.$store.commit(UPDATE_CUSTOM_WORD, { index });
    },
    updateTimeLimit: function() {
      this.$store.commit(UPDATE_TIME_LIMIT, {
        drawingTimeLimit: this.drawingTimeLimit,
      });
    },
    addRow: function() {
      this.customWords.push({ topic: "", words: "" });
    },
    start: function() {
      console.log(this.customWordsOnly)
      console.log(this.customWords)
      this.$store.commit(UPDATE_CUSTOM_WORD, { customWords: this.customWords});
      this.$store.commit(START, {customWordsOnly: this.customWordsOnly})
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.margin-top-50 {
  margin-top: 50px;
}
.ofh {
  overflow: hidden;
}
</style>
