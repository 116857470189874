import Vue from "vue";
import Vuex from "vuex";
import {
  SET_UUID,
  CONNECT,
  JOIN,
  SEND_CHAT,
  UPDATE_TIME_LIMIT,
  UPDATE_CUSTOM_WORD,
  START,
  VOTE,
  MOUSE_MOVE,
  SET_CANVAS,
  MOUSE_DOWN,
  MOUSE_UP,
  FINISH_TURN,
  UPDATE_GUESS,
  SET_PEN,
  ADJUST_PEN,
} from "./constants/mutation-types";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    roomCode: "",
    uuid: "",
    playerList: [],
    playerMap: {},
    turn: 0,
    turnTimeEnd: 0,
    turnLength: 30,
    round: 0,
    drawingHistory: [],
    chat: [],
    socket: null,
    stompClient: Stomp,
    connected: false,
    chatHistory: [],
    state: "",
    activePlayer: "",
    activeX: 0,
    activeY: 0,
    customWords: [{ topic: "test", words: "hello, word" }],
    conartist: "",
    secretWord: "",
    guess: "",
    topic: "",
    turnOrder: [],
    draw: false,
    canvas: null,
    pen: null,
    penBaseX: 0,
    penBaseY: 0,
    penHeight: 0,
  },
  getters: {
    getUUID: (state) => {
      return state.uuid;
    },
    getConnected: (state) => {
      return state.connected;
    },
    isActive: (state) => {
      return state.uuid === state.activePlayer;
    },
    getPlayersByScore: (state) => {
      return state.playerList.sort((a, b) => {
        return b.score - a.score;
      });
    },
  },
  mutations: {
    // we can use the ES2015 computed property name feature
    // to use a constant as the function name
    [ADJUST_PEN](state, { rect }) {
      state.penBaseX = rect.left;
      state.penBaseY = rect.top - (3 * state.penHeight);
    },
    [UPDATE_GUESS](state, { guess }) {
      state.guess = guess;
      const msg = {
        roomCode: state.roomCode,
        uuid: state.uuid,
        guess,
      };
      state.stompClient.send("/app/updateGuess", JSON.stringify(msg));
    },

    [SET_PEN](state, { p }) {
      var rect = p.getBoundingClientRect();
      state.penHeight = rect.bottom - rect.top;
      state.pen = p;
      console.log(state.penHeight)
    },

    [SET_CANVAS](state, { ctx }) {
      state.canvas = ctx;
    },
    [FINISH_TURN](state) {
      const msg = {
        roomCode: state.roomCode,
        uuid: state.uuid,
      };
      state.stompClient.send("/app/finishTurn", JSON.stringify(msg));
    },
    [MOUSE_DOWN](state) {
      state.draw = true;
    },
    [MOUSE_UP](state) {
      state.draw = false;
    },
    [MOUSE_MOVE](state, { x, y}) {
      let width = state.canvas.canvas.width
      let height = state.canvas.canvas.height
      x = x/width
      y = y/height
      const msg = {
        x,
        y,
        roomCode: state.roomCode,
        uuid: state.uuid,
      };
      if (state.draw && state.uuid === state.activePlayer) {
        state.stompClient.send("/app/drawXY", JSON.stringify(msg));
      } else {
        state.stompClient.send("/app/updateXY", JSON.stringify(msg));
      }
    },
    [VOTE](state, { suspect }) {
      const msg = {
        uuid: state.uuid,
        roomCode: state.roomCode,
        suspect,
      };
      state.stompClient.send("/app/vote", JSON.stringify(msg), {});
    },
    [START](state, { customWordsOnly }) {
      state.state = "ACTIVE";
      const msg = {
        uuid: state.uuid,
        name: state.playerMap[state.uuid].name,
        roomCode: state.roomCode,
        customWords: state.customWords,
        customWordsOnly,
      };
      state.stompClient.send("/app/start", JSON.stringify(msg), {});
    },
    [SET_UUID](state, payload) {
      // mutate state
      state.uuid = payload.uuid;
    },
    [UPDATE_CUSTOM_WORD](state, payload) {
      state.customWords = payload.customWords;
    },
    [UPDATE_TIME_LIMIT](state, payload) {
      const msg = {
        uuid: state.uuid,
        updateTimeLimit: payload.drawingTimeLimit,
        roomCode: state.roomCode,
      };
      state.turnLength = payload.drawingTimeLimit;
      state.stompClient.send(
        "/app/updateDrawingTimeLimit",
        JSON.stringify(msg)
      );
    },
    [JOIN](state, payload) {
      if (state.stompClient && state.connected) {
        const msg = {
          uuid: state.uuid,
          name: payload.msg,
          roomCode: state.roomCode,
        };
        state.stompClient.send("/app/connect", JSON.stringify(msg), {});
      }
    },
    [SEND_CHAT](state, { chatMsg }) {
      if (state.stompClient && state.connected) {
        const msg = {
          uuid: state.uuid,
          roomCode: state.roomCode,
          msg: chatMsg,
        };
        state.stompClient.send("/app/sendChat", JSON.stringify(msg), {});
      }
    },

    [CONNECT](state, { roomCode }) {
      state.socket = new SockJS(
        "http://144.126.213.200:8080/messaging-stomp-websocket-2.3.2.RELEASE/gs-guide-websocket"
      );
      state.stompClient = Stomp.over(state.socket);
      if (state.stompClient !== null) {
        state.stompClient.debug = function (){};
      }
      state.stompClient.connect(
        {},
        (/*frame*/) => {
          state.connected = true;
          // console.log(frame);
          state.roomCode = roomCode;
          state.stompClient.subscribe(`/topic/${roomCode}`, (tick) => {
            const command = JSON.parse(tick.body);
            // console.log(command)
            if (command[0] == "CHAT_RESPONSE") {
              state.chatHistory.push(command[1]);
            } else if (command[0] == "FULL_GAME_STATE") {
              //assignments
              state.chatHistory = command[1].chatHistory;
              state.playerMap = command[1].players;
              state.playerList = command[1].playerList;
              state.state = command[1].state;
              state.turnLength = command[1].turnLimit;
              state.host = command[1].host;
              state.secretWord = command[1].word;
              state.topic = command[1].topic;
              state.conartist = command[1].conartist;
              state.turnOrder = command[1].turnOrder;
              state.turn = command[1].turnNumber;
              state.activePlayer = command[1].activeDrawer;
              state.draw = false;
              state.turnTimeEnd = new Date(
                Date.now() + command[1].turnLimit * 1000
              );
              if (command[1].turnNumber === 0 && state.state === "ACTIVE") {
                if (state.canvas !== null)
                state.canvas.clearRect(0, 0, state.canvas.canvas.width, state.canvas.canvas.height);
              }
              //loggings
            } else if (command[0] === "VOTE_RESPONSE") {
              state.playerMap = command[1];
            } else if (command[0] === "UPDATE_XY_RESPONSE") {       
              state.activeX = state.canvas.canvas.width * command[1];
              state.activeY = state.canvas.canvas.height * command[2];
              console.log(state.activeX + state.penBaseX + "px")
              state.pen.style.left = state.activeX + state.penBaseX + "px";
              state.pen.style.top = state.activeY + state.penBaseY + "px";
            } else if (command[0] === "DRAW_RESPONSE") {
              let x = state.canvas.canvas.width * (command[1]);
              let y = state.canvas.canvas.height * (command[2]);
              let x1 = state.activeX;
              let y1 = state.activeY;
              let x2 = x;
              let y2 = y;
              let ctx = state.canvas;
              ctx.beginPath();
              ctx.lineCap = "round";
              ctx.strokeStyle = state.playerMap[
                state.activePlayer
              ].color.toLowerCase();
              ctx.lineWidth = 3;
              ctx.moveTo(x1, y1);
              ctx.lineTo(x2, y2);
              ctx.stroke();
              ctx.closePath();
              state.activeX = x;
              state.activeY = y;
              state.pen.style.left = x + state.penBaseX + "px";
              state.pen.style.top = y + state.penBaseY + "px";
            }
          });
          state.stompClient.send(
            "/app/connect",
            JSON.stringify({
              uuid: state.uuid,
              roomCode: state.roomCode,
            }),
            {}
          );
        },
        (error) => {
          console.log(error);
          state.connected = false;
        }
      );
    },
  },
});

export default store;
